import './src/css/tailwind.build.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faQuoteRight,
  faCheckCircle,
  faLightbulb,
  faChalkboardTeacher,
  faCalendarAlt,
  faComments
} from '@fortawesome/pro-solid-svg-icons';
// import { faTwitter } from '@fortawesome/pro-regular-svg-icons';
// library.add(faTwitter);
library.add(faQuoteRight);
library.add(faCheckCircle);
library.add(faLightbulb);
library.add(faChalkboardTeacher);
library.add(faCalendarAlt);
library.add(faComments);

// Polyfill for older browsers. React-Spring uses Object.entries.
if (!Object.entries) {
  Object.entries = function(obj) {
    var ownProps = Object.keys(obj),
      i = ownProps.length,
      resArray = new Array(i); // preallocate the Array
    while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];

    return resArray;
  };
}
