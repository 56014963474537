// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-april-wilkerson-js": () => import("/opt/build/repo/src/pages/april-wilkerson.js" /* webpackChunkName: "component---src-pages-april-wilkerson-js" */),
  "component---src-pages-brand-js": () => import("/opt/build/repo/src/pages/brand.js" /* webpackChunkName: "component---src-pages-brand-js" */),
  "component---src-pages-diresta-js": () => import("/opt/build/repo/src/pages/diresta.js" /* webpackChunkName: "component---src-pages-diresta-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jon-peters-js": () => import("/opt/build/repo/src/pages/jon-peters.js" /* webpackChunkName: "component---src-pages-jon-peters-js" */),
  "component---src-pages-plans-js": () => import("/opt/build/repo/src/pages/plans.js" /* webpackChunkName: "component---src-pages-plans-js" */),
  "component---src-pages-privacy-js": () => import("/opt/build/repo/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-purchase-thank-you-js": () => import("/opt/build/repo/src/pages/purchase/thank-you.js" /* webpackChunkName: "component---src-pages-purchase-thank-you-js" */),
  "component---src-pages-samurai-js": () => import("/opt/build/repo/src/pages/samurai.js" /* webpackChunkName: "component---src-pages-samurai-js" */),
  "component---src-pages-support-js": () => import("/opt/build/repo/src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-tos-js": () => import("/opt/build/repo/src/pages/tos.js" /* webpackChunkName: "component---src-pages-tos-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

